export const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    
    // Get the individual components of the date
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + currentDate.getDate()).slice(-2);
    
    // Create the formatted date string
    const formattedDate = year + '/' + month + '/' + day;
    
    return formattedDate
  }