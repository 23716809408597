import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import PateintExperience from '../assets/images/Pathient-Experience.svg';
import Enroll1 from '../assets/icons/Enroll-1.svg';
import Assessment1 from '../assets/icons/Assessment-1.svg';
import DietC1 from '../assets/icons/Diet-C-1.svg';
import OnlineSessions1 from '../assets/icons/Online-sessions-1.svg';
import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import { programDurationData } from '../utils/constants';
import { getCurrentFormattedDate } from '../utils/helpers';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const steps = [
  {
    id: '1',
    title: 'Enroll to our program',
    icon: Enroll1,
    description: 'Fill in your details and subscribe to our lung care plan',
    showEnroll: true,
  },
  {
    id: '2',
    title: 'Assessment Session',
    icon: Assessment1,
    description:
      'Our cardiopulmonary physiotherapist will reach out to you on video call to understand your condition, review your prescription, your current health status and design a customized physiotherapy program for you.',

    showEnroll: false,
  },
  {
    id: '3',
    title: 'Diet Consultation',
    icon: DietC1,
    description:
      'Our nutritionist will review your history and prepare a personalized diet plan & explain it to you over a video call.',
  },
  {
    id: '4',
    title: 'Start your 6-week sessions',
    icon: OnlineSessions1,
    description:
      'Start online sessions in a batch of your preference & convenience. Our nutritionist & physiotherapist will review your progress over the next 6 weeks.',

    showEnroll: false,
  },
];

function HowWillItWork({
  selectedProgramDuration,
  selectedHealthConditions,
  phoneNumber,
  userName,
  email,
  ctaToCall,
  ctaToWhatsapp,
}) {
  return (
    <div className="pb-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">How will it work?</h2>
      </div>

      {steps.map(({ id, title, description, icon, showEnroll, showBookNow }, index) => (
        <div key={id} className="p-4 shadow-xl border-2 rounded-md mt-4 ">
          <div className="flex gap-2">
            <div className="flex-1">
              <h4 className="text-xl leading-5 font-medium text-green-700">{title}</h4>
              <p className="text-sm mt-2">{description}</p>
            </div>
            <div className="flex flex-col items-center m-auto">
              <img src={icon} className="max-h-24" alt={title} />
              <h4 className="font-medium mt-1">Step {index + 1}</h4>
            </div>
          </div>

          {showEnroll && (
            <div className={`${description.length > 100 && 'mt-4'}`}>
              <button
                onClick={() => {
                  const currentDate = getCurrentFormattedDate();
                  sendEventsToAnalyticsSystems('clicked_enroll_now', {
                    health_condition: selectedHealthConditions,
                    program_duration: selectedProgramDuration,
                    phone_number: phoneNumber,
                    email: email,
                    name: userName,
                    date_of_enquiry: currentDate,
                  });

                  if (ctaToCall) {
                    window.location.href = `tel:${ctaToCall}`;
                  } else if (ctaToWhatsapp) {
                    window.location.href = `https://wa.me/${ctaToWhatsapp}?text=Hi%2C%20I%20am%20interested%20in%20Breathefree%20Lung%20Care%20Program.%20Please%20share%20further%20details%20with%20me.`;
                  } else {
                    window.location.href = programDurationData[selectedProgramDuration].paymentLink;
                  }
                }}
                className="mx-auto text-white font-bold p-2 px-8 bg-gradient-to-tr from-green-600 to-green-700 rounded-full"
              >
                Enroll now
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default HowWillItWork;
