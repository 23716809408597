import React, { memo } from 'react';

import { Carousel } from 'react-responsive-carousel';

import Expert1 from '../assets/images/Devanshi.png';
import Expert2 from '../assets/images/Meenal.png'
import Expert3 from '../assets/images/Alisha.png'
// import { sendEventsToAnalyticsSystems } from '../utils/analytics';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const ourExperts = [
  {
    id: '1',
    name: 'Dr. Devanshi',
    bio: 'B.P.T, M.P.T-CVRS',
    imageSrc: Expert1,
  },
  {
    id: '2',
    name: 'Dt. Meenal',
    bio: 'Clinical Dietitian & Nutritionist',
    imageSrc: Expert2,
  },
  {
    id: '3',
    name: 'Dr. Alisha',
    bio: 'Health Coach',
    imageSrc: Expert3,
  },
];

function MeetOurExperts() {
  return (
    <div className="pt-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="text-center mb-4 text-4xl tracking-tight font-medium text-gray-900">Meet our experts</h2>
      </div>

      <Carousel preventMovementUntilSwipeScrollTolerance swipeScrollTolerance={60} showStatus={false} infiniteLoop autoPlay showThumbs={false} showArrows={false}>
        {ourExperts.map(({ id, imageSrc, name, bio }) => (
          <div className="mb-12 bg-gradient-to-tr px-2 py-4 flex-col justify-center items-center" key={id}>
            <div className='w-48 h-48 rounded-full mx-auto'>
              <img src={imageSrc} className="aspect-square h-full bg-contain" alt={name} />
            </div>
            <h3 className="text-center text-2xl mt-2 font-medium line-clamp-2 text-green-600">{name}</h3>
            <p className="text-gray-700 font-medium">{bio}</p>

            {/* <button
              onClick={() => {
                sendEventsToAnalyticsSystems('clicked_experts_read_more');
              }}
              className="text-green-600 border-[1px] border-green-700 rounded-full px-4 py-2 mt-4 active:opacity-75"
            >
              Read more
            </button> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default memo(MeetOurExperts);
