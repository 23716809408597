/* global smartech */
import mixpanel from 'mixpanel-browser';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDtr0MIjHZACBZJYdMFvYh7sXPJr4kvKJY",
    authDomain: "breathefree-d0a6c.firebaseapp.com",
    databaseURL: "https://breathefree-d0a6c-default-rtdb.firebaseio.com",
    projectId: "breathefree-d0a6c",
    storageBucket: "breathefree-d0a6c.appspot.com",
    messagingSenderId: "265869001682",
    appId: "1:265869001682:web:0df99b092cb5412056be0b",
    measurementId: "G-S2JZGNMGTE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const firebaseAnalytics = getAnalytics(app);


export function sendEventsToAnalyticsSystems(eventName, payload, id) {
    mixpanel.init('6a3258ca35231e2bd6848a417444a8c9');

    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_content = urlParams.get('utm_content');
    const utm_campaign = urlParams.get('utm_campaign');

    if (! payload) {
        payload = {};
    }

    if (utm_source) {
        payload['utm_source'] = utm_source;
    }
    if (utm_medium) {
        payload['utm_medium'] = utm_medium;
    }
    if (utm_content) {
        payload['utm_content'] = utm_content;
    }
    if (utm_campaign) {
        payload['utm_campaign'] = utm_campaign;
    }

    if(Object.keys(payload).length > 0 && id){
        mixpanel.identify(id);
        mixpanel.track(eventName, payload);
        smartech('dispatch', eventName, payload);
        logEvent(firebaseAnalytics, eventName, payload);
        console.log("Event With Payload", eventName, payload);
    }
    else if (Object.keys(payload).length > 0) {
        mixpanel.track(eventName, payload);
        smartech('dispatch', eventName, payload);
        logEvent(firebaseAnalytics, eventName, payload);
        console.log("Event With Payload", eventName, payload);
    } else {
        mixpanel.track(eventName);
        smartech('dispatch', eventName, {});
        logEvent(firebaseAnalytics, eventName, {});
        console.log("Event Without Payload", eventName);
    }
}



