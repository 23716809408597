const { REACT_APP_BASE_URL_CAREPLIX } = process.env;
const { REACT_APP_BASE_URL } = process.env;
const { REACT_APP_API_KEY } = process.env;
const { REACT_APP_SECREATE_KEY } = process.env;

export const BASE_URL_CAREPLIX = `${REACT_APP_BASE_URL_CAREPLIX}`;
export const BASE_URL = `${REACT_APP_BASE_URL}`;
export const ApiKey = `${REACT_APP_API_KEY}`;
export const SecreateKey = `${REACT_APP_SECREATE_KEY}`;
export const vitalspath = '/vital-scan';
