import React, { useState } from 'react';

import { ChevronUp, ChevronDown } from 'lucide-react';
import { sendEventsToAnalyticsSystems } from '../utils/analytics';

const faqs = [
  {
    id: '1',
    question: 'Who is this program for?',
    answer:
      'Individuals with chronic lung conditions such as COPD, Asthma or any breathing difficulties can benefit from the program.',
  },
  {
    id: '2',
    question: 'Are there any side effects of the program?',
    answer:
      'No, there are no side effects of the program. The entire program is a combination of exercises and diet changes which are governed by experts ensuring thorough safety. It is however always advisable to consult with your doctor.',
  },
  {
    id: '3',
    question: 'Can I enroll for this program along with current medications?',
    answer:
      'Yes, you can. On the contrary, properly coordinating dietary choices with medications can enhance the effectiveness of prescribed treatments, reducing the need for rescue medications.',
  },
  {
    id: '4',
    question: 'Will you change or reduce my prescribed medicines?',
    answer:
      'No, this program does not make any change to your prescribed medicines. For medical advice, please reach out to your doctor.',
  },
  {
    id: '5',
    question: 'What languages is this program available in?',
    answer:
      'This program is available in English, Hindi, Marathi and Gujarati.',
  },
];

function Faqs() {
  const [faqIndexOpen, setFaqIndexOpen] = useState(-1);

  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">Frequently asked questions</h2>
      </div>

      <div className="bg-gray-200 rounded-xl">
        {faqs.map(({ id, question, answer }, index) => (
          <div key={id} className="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200">
            <h2 className="mb-0">
              <button
                className="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-normal transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none"
                type="button"
                onClick={() => {
                  sendEventsToAnalyticsSystems('faq_opened');
                  if (faqIndexOpen === index) {
                    setFaqIndexOpen(-1);
                  } else {
                    setFaqIndexOpen(index);
                  }
                }}
              >
                {question}
                <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                  {faqIndexOpen === index ? (
                    <ChevronDown size={24} color="black" />
                  ) : (
                    <ChevronUp size={24} color="black" />
                  )}
                </span>
              </button>
            </h2>
            <div className={`${faqIndexOpen !== index && 'hidden'} border-0`}>
              <div className="py-2 pt-0 px-5 text-neutral-500 dark:text-neutral-300">{answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
