import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const reviews = [
  {
    id: '1',
    name: 'Monika Khema',
    description:
      'I am very happy with Doctor, she is fantastic. The classes that were suggested by my doctor were expensive, so I had to back out. But now it is pocket friendly and the content is very similar. Thank you so much.',
    rating: 5,
  },
  {
    id: '2',
    name: 'Arvind Sharma',
    description:
      'I came back and again travelling shortly. I pass through my day nicely & in a healthy way with daily exercise and I am active & standing 8 hours a day to deliver my work. Thanks for your advice and help.',
    rating: 5,
  },
  {
    id: '3',
    name: 'Krishna Kumar',
    description:
      'I enrolled for breathing exercises program. It did wonders for me. The therapist was par excellence & I am joining the program again. I have got over my breathlessness, especially after getting the breathing exercises and overall loved the app, Breathefree.',
    rating: 5,
  },
];

function CustomerReviews() {
  return (
    <div className="pt-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">Customer Reviews</h2>
      </div>

      <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={60}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay
        showArrows={false}
      >
        {reviews.map(({ id, name, description, rating }) => (
          <div className="mb-12 w-[95%] bg-gradient-to-tr  border-gray-200 border-[1px] rounded-lg px-2 py-4" key={id}>
            <h3 className="text-left text-xl font-medium line-clamp-2">{name}</h3>
            <div className="flex mt-1">
              {[...Array(rating)].map((value, index) => (
                <div className='w-4 h-4 mr-1'>
                  <svg
                    key={index}
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.792 5.02015L16.8856 5.74166C17.3076 5.80112 17.4756 6.30327 17.1697 6.59134L13.4798 10.0641C13.3583 10.1777 13.3023 10.3429 13.331 10.5041L14.1954 15.4146C14.2664 15.8216 13.8253 16.1308 13.4484 15.9392L8.89418 13.6161C8.74397 13.5395 8.56507 13.5395 8.41486 13.6161L3.85515 15.9286C3.47688 16.1203 3.03579 15.8097 3.10953 15.4027L3.98488 10.4936C4.01356 10.3323 3.95893 10.1672 3.83739 10.0522L0.155757 6.57417C-0.14877 6.28609 0.0191977 5.78394 0.442531 5.72448L5.53756 5.01354C5.70552 4.98976 5.85028 4.88801 5.92538 4.74133L8.20866 0.277502C8.39847 -0.0925008 8.94335 -0.0925008 9.1318 0.277502L11.4055 4.74661C11.4806 4.89329 11.6254 4.99637 11.7933 5.01883L11.792 5.02015Z"
                      fill="#F4C914"
                    />
                  </svg>
                </div>
              ))}
            </div>

            <p className="text-left text-[15px] text-gray-800 font-normal mt-4">{description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CustomerReviews;
