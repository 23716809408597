import React, { memo } from 'react';
import { Carousel } from 'react-responsive-carousel';

import LungFunction1 from '../assets/images/Reduce Breathlessness.png';
import GoodLife from '../assets/images/Make Control Over Life (1).png';
import EnergyImg from '../assets/images/Be more active.png';
import Weight1 from '../assets/images/Achieve Your Ideal Weight.png';
import Sleep1 from '../assets/images/Improve Sleep Quality.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const programBenefitsCardData = [
  {
    id: '1',
    title: 'Reduce Breathlessness',
    imageSrc: LungFunction1,
    description:
      'You will experience reduced intensity and frequency of breathing issues and enhanced overall respiratory health.',
  },
  {
    id: '2',
    title: 'Improve Sleep Quality',
    imageSrc: Sleep1,
    description: 'You will experience fewer episodes of reduced sleep by night time breathlessness.',
  },
  {
    id: '3',
    title: 'Achieve Your ideal Weight',
    imageSrc: Weight1,
    description: 'You can expect to achieve your weight goals with the right diet and exercise plan.',
  },

  {
    id: '4',
    title: 'Be More Active In The Day',
    imageSrc: EnergyImg,
    description: 'Get increased energy and vitality to engage in physical activities and enjoy an active lifestyle.',
  },
  {
    id: '5',
    title: 'More Control Over Life',
    imageSrc: GoodLife,
    description:
      'Take charge of your life and experience better symptom control. You will experience an improved quality of life.',
  },
];

function ProgramBenefits() {
  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl scroll-y">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">
          What will this <br /> program do:
        </h2>
      </div>

      <Carousel preventMovementUntilSwipeScrollTolerance swipeScrollTolerance={60} showThumbs={false} showStatus={false} infiniteLoop autoPlay showArrows={false}>
        {programBenefitsCardData.map(({ id, title, description, imageSrc }) => (
          <div className="h-[440px] flex flex-col gap-3 mb-12 w-[85%] mx-auto bg-gradient-to-tr  from-gray-100 to-gray-200 rounded-lg p-6" key={id}>
            <h3 className="text-left text-2xl font-medium line-clamp-2">{title}</h3>
            <div className="my-2 h-52 overflow-hidden">
              <img src={imageSrc} alt={'program-benefits'} className="aspect-square bg-cover" />
            </div>
            <p className="text-left text-md font-normal">{description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default memo(ProgramBenefits);
