import { Carousel } from 'react-responsive-carousel';

import IndianBusinessMan from '../assets/images/cheerful-indian-businessman.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { memo } from 'react';

const videos = [
  {
    id: '1',
    videoLink: 'https://www.youtube.com/embed/YmbSCpImai4',
  },
  {
    id: '2',
    videoLink: 'https://www.youtube.com/embed/_zL0VYqesGU',
  },
];

function VideoReview() {
  return (
    <section className="py-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">
          What the users of the program have to say
        </h2>
      </div>

      {/* <div className="mb-12 w-full mx-auto rounded-lg p-0  px-4">
        <iframe
          title="Customer Review"
          className="rounded-lg w-full h-full"
          src={'https://www.youtube.com/embed/_zL0VYqesGU'}
        ></iframe>
      </div> */}

      <Carousel preventMovementUntilSwipeScrollTolerance swipeScrollTolerance={60} showThumbs={false} showStatus={false} infiniteLoop showArrows={false}>
        {videos.map(({ id, videoLink }) => (
          <div className="mb-12 w-full mx-auto rounded-lg p-0" key={id}>
            <iframe title={id} className="rounded-lg w-full h-full" src={videoLink}></iframe>
          </div>
        ))}
      </Carousel>
    </section>
  );
}

export default memo(VideoReview);
