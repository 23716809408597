import { memo, useEffect, useState } from 'react';

import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import { programDurationData } from '../utils/constants';
import { getCurrentFormattedDate } from '../utils/helpers';

function Footer({
  selectedProgramDuration,
  selectedHealthConditions,
  phoneNumber,
  userName,
  email,
  ctaToCall,
  ctaToWhatsapp,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = () => {
    let heightToShowFrom = 1350;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToShowFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    let prev = 0;
    let delay = 500;

    window.addEventListener('scroll', () => {
      let now = new Date().getTime();

      if (now - prev > delay) {
        prev = now;

        // call function only after the delay
        listenToScroll();
      }
    });
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  return (
    <footer
      id="footer"
      className={`${
        !isVisible ? 'hidden' : 'visible'
      } bg-white w-full z-20 bottom-0 start-0 border-t-2 rounded-t-3xl shadow-2xl fixed`}
    >
      <div className="max-w-screen-xl flex items-center justify-start gap-2 mx-auto p-4">
        <div className="w-[50%]">
          {programDurationData[selectedProgramDuration].strikeThroughPrice && (
            <p className="text-gray-600 text-2xl mb-2">
              <s>₹{programDurationData[selectedProgramDuration].strikeThroughPrice}</s>
            </p>
          )}
          <p className="text-5xl font-semibold text-gray-500">₹{programDurationData[selectedProgramDuration].price}</p>
          <p className="text-gray-500" dangerouslySetInnerHTML={{ __html: programDurationData[selectedProgramDuration].lineUnderPrice }}></p>
        </div>

        <button
          onClick={() => {
            const currentDate = getCurrentFormattedDate();
            sendEventsToAnalyticsSystems('clicked_join_now_sticky', {
              health_condition: selectedHealthConditions,
              program_duration: selectedProgramDuration,
              phone_number: phoneNumber,
              email: email,
              name: userName,
              date_of_enquiry: currentDate,
            });

            if (ctaToCall) {
              window.location.href = `tel:${ctaToCall}`;
            } else if (ctaToWhatsapp) {
              window.location.href = `https://wa.me/${ctaToWhatsapp}?text=Hi%2C%20I%20am%20interested%20in%20Breathefree%20Lung%20Care%20Program.%20Please%20share%20further%20details%20with%20me.`;
            } else {
              window.location.href = programDurationData[selectedProgramDuration].paymentLink;
            }
          }}
          type="submit"
          className="text-white w-[50%] font-bold px-2 py-4 bg-gradient-to-tr from-green-600 to-green-700 rounded-full"
        >
          Join now
        </button>
      </div>
    </footer>
  );
}

export default memo(Footer);
