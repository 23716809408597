export const programDurationData = {
  '6-weeks': {
    planTitle: "6 Weeks Plan",
    price: '3999',
    strikeThroughPrice: '11999',
    lineUnderPrice: '<s>5999</s> Additional Rs 2000/- Off!',
    items: [
      '12 Online Physiotheraphy Sessions',
      'Personalised diet plans',
      'Dedicated Health Coach',
      'Exclusive discounts on medicines',
    ],
    // paymentLink: 'https://smartpay.easebuzz.in/102488/2c3350eec3474fe2ade55cfb6a6fc148',
    paymentLink: 'https://smartpay.easebuzz.in/102488/1f218b36512441f6a8ba9f2d88608ff4_3999',
  },
  '3-weeks': {
    planTitle: "3 Weeks Plan",
    price: '4499',
    // strikeThroughPrice: '6999',
    lineUnderPrice: 'MRP inclusive of tax',
    items: [
      '6 Online Physiotherapy Sessions',
      'Personalised diet plans',
      'Dedicated Health Coach',
    ],
    paymentLink: 'https://smartpay.easebuzz.in/102488/1f218b36512441f6a8ba9f2d88608ff4_4499',
  },
};
