import { Carousel } from 'react-responsive-carousel';

import Banner1 from '../assets/images/CDHL-Landing-Page-Banner-1.jpg';
import Banner2 from '../assets/images/CDHL-Landing-Page-Banner-2.jpg';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import { programDurationData } from '../utils/constants';
import { getCurrentFormattedDate } from '../utils/helpers';
import { memo, useState } from 'react';

const images = [
  {
    id: '1',
    imageSrc: Banner1,
  },
  {
    id: '2',
    imageSrc: Banner2,
  },
];

const healthContitions = ['Asthma', 'COPD', 'Breathlessness', 'Allergic Rhinitis', 'Other breathing issues'];

function HeroSection({
  selectedHealthConditions,
  setSelectedHealthConditions,
  selectedProgramDuration,
  setSelectedProgramDuration,
  phoneNumber,
  userName,
  email,
  ctaToWhatsapp,
  ctaToCall,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <section className="py-8 px-4 mx-auto max-w-screen-xl  mt-16 rounded-xl border-b-2 border-gray-200 shadow-2xl shadow-gray-400">
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={60}
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        showArrows={false}
        emulateTouch={true}
        verticalSwipe={true}
        onChange={(newImageIndex) => {
          setCurrentImageIndex(newImageIndex);
        }}
        selectedItem={currentImageIndex}
      >
        {images.map(({ id, imageSrc }) => (
          <div className="mb-12 w-[85%] mx-auto" key={id}>
            <img src={imageSrc} alt={'program-benefits'} className="rounded-lg bg-cover" />
          </div>
        ))}
      </Carousel>

      <div className="overflow-x-auto my-8 w-full flex gap-4 px-2">
        {images.map(({ id, imageSrc }, index) => (
          <div
            onClick={() => setCurrentImageIndex(index)}
            key={id}
            className={`custom-carousel-thumb ${currentImageIndex === index && 'selected'} h-28 w-24`}
          >
            <img src={imageSrc} alt={`hero${id}`} className="rounded-full h-full w-full bg-contain" />
          </div>
        ))}
      </div>

      <div>
        <div className="flex text-2xl">
          <span className="text-green-700 mr-2">1.</span>
          <p className="text-gray-700">Select current health condition</p>
        </div>

        <div className="flex flex-wrap gap-2 pl-4 pt-10 pb-3 border-l-2 border-green-700 mt-[-20px]">
          {healthContitions.map((condition) => {
            const isCurrentHealthConditionSelected = selectedHealthConditions.includes(condition);
            return (
              <div
                key={condition}
                onClick={() => {
                  sendEventsToAnalyticsSystems('health_condition_selected');
                  if (isCurrentHealthConditionSelected) {
                    const newSelectedHealthConditions = selectedHealthConditions.filter(
                      (healthCondition) => healthCondition !== condition,
                    );
                    setSelectedHealthConditions(newSelectedHealthConditions);
                  } else {
                    setSelectedHealthConditions((prev) => [...prev, condition]);
                  }
                }}
                className={`${
                  isCurrentHealthConditionSelected ? 'text-white bg-green-600' : 'text-green-600 bg-gray-200'
                } p-3 px-6 rounded-full font-medium`}
              >
                {condition}
              </div>
            );
          })}
        </div>
      </div>

      <br />
      <div className="">
        <div className="flex text-2xl ml-[-4px]">
          <span className="text-green-700 mr-2">2.</span>
          <p className="text-gray-700">Program Duration</p>
        </div>

        <div className="pb-3 pl-2 pt-4 border-l-2 border-green-700">
          <div className="flex gap-4 ">
            <div
              onClick={() => {
                sendEventsToAnalyticsSystems('plan_selected');
                setSelectedProgramDuration('6-weeks');
              }}
              className={`${
                selectedProgramDuration === '6-weeks' ? 'text-white bg-green-600' : 'text-green-600 bg-gray-200'
              } p-3 px-8 rounded-3xl font-medium`}
            >
              6 Weeks <br /> ₹3999
            </div>
            <div
              onClick={() => {
                sendEventsToAnalyticsSystems('plan_selected');
                setSelectedProgramDuration('3-weeks');
              }}
              className={`${
                selectedProgramDuration === '3-weeks' ? 'text-white bg-green-600' : 'text-green-600 bg-gray-200'
              } p-3 px-8 rounded-3xl font-medium`}
            >
              3 Weeks <br /> ₹4499
            </div>
          </div>

          {/* <div className="flex items-center mt-6 gap-2 bg-gray-200 rounded-xl py-2 px-4">
            <img src={DoctorProfile} alt={'Doctor-Profile'} className="h-10" />
            <p className="text-gray-700 text-lg">
              <span className="text-green-700 font-medium">Doctor Recommendation: </span> Use for 12 weeks for better
              effectiveness
            </p>
          </div> */}
        </div>
      </div>

      <div className="max-w-screen-xl flex items-center justify-start gap-2 mx-auto p-4">
        <div className="w-[50%]">
          {programDurationData[selectedProgramDuration].strikeThroughPrice && (
            <p className="text-gray-600 text-2xl mb-2">
              <s>₹{programDurationData[selectedProgramDuration].strikeThroughPrice}</s>
            </p>
          )}
          <p className="text-5xl font-semibold text-gray-500">₹{programDurationData[selectedProgramDuration].price}</p>
          <p className="text-gray-500" dangerouslySetInnerHTML={{ __html: programDurationData[selectedProgramDuration].lineUnderPrice }}></p>
        </div>

        <button
          onClick={() => {
            const currentDate = getCurrentFormattedDate();
            sendEventsToAnalyticsSystems('clicked_join_now', {
              health_condition: selectedHealthConditions,
              program_duration: selectedProgramDuration,
              phone_number: phoneNumber,
              email: email,
              name: userName,
              date_of_enquiry: currentDate,
            });

            if (ctaToCall) {
              window.location.href = `tel:${ctaToCall}`;
            } else if (ctaToWhatsapp) {
              window.location.href = `https://wa.me/${ctaToWhatsapp}?text=Hi%2C%20I%20am%20interested%20in%20Breathefree%20Lung%20Care%20Program.%20Please%20share%20further%20details%20with%20me.`;
            } else {
              window.location.href = programDurationData[selectedProgramDuration].paymentLink;
            }
          }}
          type="submit"
          className="text-white w-[50%] font-bold px-2 py-4 bg-gradient-to-tr from-green-600 to-green-700 rounded-full"
        >
          Join now
        </button>
      </div>
    </section>
  );
}

export default memo(HeroSection);
