import { Link, Navigate, useParams } from 'react-router-dom';

// import { ReactComponent as BackArrow } from '../assets/icons/BackArrow.svg';
import { ReactComponent as Arrow } from '../assets/icons/Arrow.svg';
import InstructionFaceScan from '../assets/images/InstructionFaceScan.png';
import InstructionFingerScan from '../assets/images/InstructionFingerScan.png';
import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import { vitalspath } from '../utils/ApiConstants';
const ScanInstructions = () => {
  const { authtoken, scanType, scanToken, emp_id, secondary_uuid, dob, gender, height, weight } = useParams();
  const btnSubmit = () => {
    const payload = {
      status: "proceed button clicked"
    }
    sendEventsToAnalyticsSystems('button_click', payload, secondary_uuid);
  };

  return scanType === 'face' || scanType === 'finger' ? (
    <section className="p-8">
      <div className="flex items-center text-primary">
        {/* <Link
          className="flex-shrink-0 p-1 pr-2.5"
          to={`$/scan-instructions/${authtoken}/${scanType}/${authtoken}/${scanToken}/${emp_id}/${dob}/${gender}/${height}/${weight}`}
          replace
        >
          <BackArrow className="h-2.5 w-2.5" />
        </Link> */}
        <h3 className="text-sm font-medium">Instructions</h3>
      </div>

      <div className="my-6 mx-auto w-48">
        <img
          className="h-36 w-36 mx-auto"
          src={scanType === 'face' ? InstructionFaceScan : InstructionFingerScan}
          alt="instructions"
        />
        <h3 className="mt-4 text-primary text-sm font-medium capitalize">{scanType} Scan</h3>
        <ol className="mt-2 ml-2.5 list-outside list-decimal space-y-1.5">
          {(scanType === 'face'
            ? [
                'The ambient light should be bright and consistent and must be white.',
                'Remove your masks, eye-wear, or clothing obstruction the face for the duration of scan.',
                'Keep your face and phone steady to avoid movements during the scan.',
                'For light mode: Make sure your face is mostly covered by the blue circle.',
                'If prompted, allow the camera permission.',
              ]
            : [
                'Place your finger on the rear primary camera and the flashlight.',
                'Make sure the screen looks bright red in color.',
                'If the flash is not turned on then place some light behind the phone.',
                'If prompted, allow the camera permission.',
              ]
          ).map((listItem, index) => (
            <li key={index} className="list-item text-darkgray text-xxs">
              {listItem}
            </li>
          ))}
        </ol>
        <Link
          className="max-w-max mt-6 mx-auto flex items-center space-x-2 rounded-full px-4 py-2 bg-secondary text-white"
          to={`${vitalspath}/select-posture`}
          state={{ authtoken, scanType, scanToken, emp_id, dob, gender, height, weight }}
          style={{ backgroundColor: '#7528cc' }}
          onClick={btnSubmit}
        >
          <span className="flex-shrink-0 text-xs font-medium">Proceed</span>
          <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
        </Link>
      </div>
    </section>
  ) : (
    <Navigate
      to={`${vitalspath}/scan-instructions/${authtoken}/${scanType}/${authtoken}/${scanToken}/${emp_id}/${dob}/${gender}/${height}/${weight}`}
      replace
    />
  );
};

export default ScanInstructions;
