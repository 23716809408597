import React, { useEffect, useState } from 'react';
import LungCareNavbar from './LungCareNavbar';
import LungCarePricingPlan from './LungCarePricingPlan';
import ProgramBenefits from './ProgramBenefits';
// import DiscountCard from './DiscountCard';
import PatientExperience from './PatientExperience';
import HowWillItWork from './HowWillItWork';
import Faqs from './Faqs';
import CustomerReviews from './CustomerReviews';
import MeetOurExperts from './MeetOurExperts';
import FillDetails from './FillDetails';
import Footer from './Footer';
import HeroSection from './HeroSection';
import VideoReview from './VideoReview';

import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import WhatsAppLogo from '../assets/icons/whatsapp-logo.svg';

function LungCare({ ctaToCall, ctaToWhatsapp }) {
  const [selectedHealthConditions, setSelectedHealthConditions] = useState(['Asthma']);
  const [selectedProgramDuration, setSelectedProgramDuration] = useState('6-weeks');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    sendEventsToAnalyticsSystems('page_open');

    const urlParams = new URLSearchParams(window.location.search);
    // Check if 'click_join_now' parameter exists
    if (urlParams.has('click_join_now')) {
      // Get the value of 'click_join_now'
      const clickJoinNowValue = urlParams.get('click_join_now');
      if (clickJoinNowValue === '1') {
        sendEventsToAnalyticsSystems('click_join_now');
        sendEventsToAnalyticsSystems('automatic_click_join_now');
        window.location.href = 'https://smartpay.easebuzz.in/102488/1f218b36512441f6a8ba9f2d88608ff4_3999';
      }
    }
  });

  const openURL = () => {
    sendEventsToAnalyticsSystems('clicked_sticky_whatsApp');

    if (window?.ReactNativeWebView) {
      const phoneNumber = '918657789425';
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ key: 204, action: 'openURL', url: `https://wa.me/${phoneNumber}` }));
    } else {
      window.location.href = ctaToCall ? `tel:${ctaToCall}` : 'https://wa.me/918657789425';
    }
  };

  return (
    <div className="bg-gray-50 overflow-hidden">
      <LungCareNavbar />
      <HeroSection
        selectedHealthConditions={selectedHealthConditions}
        setSelectedHealthConditions={setSelectedHealthConditions}
        selectedProgramDuration={selectedProgramDuration}
        setSelectedProgramDuration={setSelectedProgramDuration}
        email={email}
        userName={userName}
        phoneNumber={phoneNumber}
        ctaToCall={ctaToCall}
        ctaToWhatsapp={ctaToWhatsapp}
      />
      <LungCarePricingPlan selectedProgramDuration={selectedProgramDuration} />
      <ProgramBenefits />
      {/* <DiscountCard /> */}
      <VideoReview />
      <PatientExperience />
      <HowWillItWork
        selectedHealthConditions={selectedHealthConditions}
        selectedProgramDuration={selectedProgramDuration}
        email={email}
        userName={userName}
        phoneNumber={phoneNumber}
        ctaToCall={ctaToCall}
        ctaToWhatsapp={ctaToWhatsapp}
      />
      <MeetOurExperts />
      <FillDetails
        selectedHealthConditions={selectedHealthConditions}
        selectedProgramDuration={selectedProgramDuration}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        setEmail={setEmail}
        email={email}
        userName={userName}
        setUserName={setUserName}
        ctaToCall={ctaToCall}
        ctaToWhatsapp={ctaToWhatsapp}
      />
      <CustomerReviews />
      <Faqs />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {
        <button
          onClick={openURL}
          className="fixed shadow-lg bottom-40 z-30 right-2 bg-green-500  rounded-full border-none p-3"
        >
          <img src={WhatsAppLogo} className="mx-auto" alt="whatsapp-logo" />
        </button>
      }
      <Footer
        selectedHealthConditions={selectedHealthConditions}
        selectedProgramDuration={selectedProgramDuration}
        email={email}
        userName={userName}
        phoneNumber={phoneNumber}
        ctaToCall={ctaToCall}
        ctaToWhatsapp={ctaToWhatsapp}
      />
    </div>
  );
}

export default LungCare;
