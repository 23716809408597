import { Navigate, createBrowserRouter } from 'react-router-dom';
import NotFound from './vitals-screens/404';
import SelectOption from './vitals-screens/SelectOption';
import ScanInstructions from './vitals-screens/ScanInstructions';
import SelectPosture from './vitals-screens/SelectPosture';
import FaceScan from './vitals-screens/FaceScan';
import FingerScan from './vitals-screens/FingerScan';
import ScanResult from './vitals-screens/ScanResult';
import { vitalspath } from './utils/ApiConstants';
import LungCare from './lung-care-landing/LungCare';

export const router = createBrowserRouter([
  { index: true, path: '/', element: <Navigate to={`${vitalspath}/select-option`} replace /> },
  { path: `${vitalspath}/select-option`, element: <SelectOption /> },
  { path: `/lung-care-landing1`, element: <LungCare  ctaToCall={"8657789425"} /> },
  { path: `/lung-care-landing2`, element: <LungCare ctaToWhatsapp={"918657789425"}  /> },
  { path: `/lung-care-landing`, element: <LungCare  /> },
  {
    path: `${vitalspath}/scan-instructions/:authtoken/:scanType/:scanToken/:emp_id/:secondary_uuid/:dob/:gender/:height/:weight`,
    element: <ScanInstructions />,
  },
  { path: `${vitalspath}/select-posture`, element: <SelectPosture /> },
  { path: `${vitalspath}/scan-result`, element: <ScanResult /> },
  { path: `${vitalspath}/face-scan`, element: <FaceScan /> },
  { path: `${vitalspath}/finger-scan`, element: <FingerScan /> },
  { path: '*', element: <NotFound /> },
]);
