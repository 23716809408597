import React, { useState } from 'react';
import { sendEventsToAnalyticsSystems } from '../utils/analytics';
import { programDurationData } from '../utils/constants';
import { getCurrentFormattedDate } from '../utils/helpers'

function FillDetails({
  selectedHealthConditions,
  selectedProgramDuration,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  userName,
  setUserName,
  ctaToCall,
  ctaToWhatsapp
}) {
  const [formError, setFormError] = useState('');

  function isValidEmail(email) {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidPhoneNumber(phoneNumber) {
    // Basic number validation and length check for 10 digits
    return /^\d{10}$/.test(phoneNumber);
  }

  const handleFormSubmit = () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      setFormError('Please add a valid 10 digit mobile number');
      return;
    }

    if (email) {
      if (!isValidEmail(email)) {
        setFormError('Please enter a valid e-mail address');
        return;
      }
    }

    const currentDate = getCurrentFormattedDate();
    sendEventsToAnalyticsSystems('clicked_book_now', {
      health_condition: selectedHealthConditions,
      program_duration: selectedProgramDuration,
      phone_number: phoneNumber,
      email: email,
      name: userName,
      date_of_enquiry: currentDate
    });

    if(ctaToCall) {
      window.location.href = `tel:${ctaToCall}`
    }else if(ctaToWhatsapp) {
      window.location.href = `https://wa.me/${ctaToWhatsapp}?text=Hi%2C%20I%20am%20interested%20in%20Breathefree%20Lung%20Care%20Program.%20Please%20share%20further%20details%20with%20me.`
    }else {
      window.location.href = programDurationData[selectedProgramDuration].paymentLink;
    }
    
  };

  return (
    <div className="pt-8 px-4 mx-auto max-w-screen-xl mb-6">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">
          Fill in your details & we will call you
        </h2>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        {formError && <p className="text-red-500 text-center">*{formError}</p>}
        <div className="bg-gray-100 px-4 flex items-center py-2 rounded-full my-3 text-lg">
          <span>+91</span>
          <span className="text-red-600 ml-2">*</span>
          <input
            value={phoneNumber}
            onChange={({ target }) => {
              setPhoneNumber(target.value);
            }}
            required
            className="border-none outline-none bg-gray-100 pl-2"
            type="tel"
            placeholder="Enter phone number"
          />
        </div>
        <div className="bg-gray-100 px-4 flex items-center py-2 rounded-full my-3 text-lg">
          <input
            value={email}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
            className="border-none outline-none bg-gray-100 pl-2"
            type="email"
            placeholder="Enter your email"
          />
        </div>
        <div className="bg-gray-100 px-4 flex items-center py-2 rounded-full my-3 text-lg">
          <span className="text-red-600">*</span>
          <input
            value={userName}
            onChange={({ target }) => {
              setUserName(target.value);
            }}
            required
            className="border-none outline-none bg-gray-100 pl-2"
            type="text"
            placeholder="Enter your name"
          />
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="text-white font-bold p-3 px-8 bg-gradient-to-tr from-green-500 to-green-600 rounded-full w-full"
          >
            Book now
          </button>
        </div>
      </form>
    </div>
  );
}

export default FillDetails;
