import React, { memo } from 'react';

import { programDurationData } from '../utils/constants';

function LungCarePricingPlan({ selectedProgramDuration }) {
  const price = programDurationData[selectedProgramDuration].price;
  const strikeThroughPrice = programDurationData[selectedProgramDuration].strikeThroughPrice;
  const planTitle = programDurationData[selectedProgramDuration].planTitle;
  const planItems = programDurationData[selectedProgramDuration]?.items;

  return (
    <section className="bg-gray-50">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900">
            Lung Care <br /> Program includes
          </h2>
        </div>

        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div className="flex flex-col py-6 mx-auto max-w-lg text-left text-gray-900 bg-gray-100 rounded-lg border border-gray-200 shadow">
            <h3 className="text-3xl font-semibold px-6">{planTitle}</h3>

            <div className="flex justify-start items-baseline my-8 w-full bg-white shadow-lg px-6 py-3">
              <span className="mr-4 text-5xl font-extrabold text-green-600">₹{price}</span>
              {strikeThroughPrice &&
                <span className="text-gray-600 text-3xl">
                  <s>₹{strikeThroughPrice}</s>
                </span>
              }
            </div>
            <ul className="space-y-4 text-left px-6">
              {planItems?.map((item) => (
                <li key={item} className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(LungCarePricingPlan);
