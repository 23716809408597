import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import BLSNS from '../assets/icons/BLNS 1.svg';
import Group from '../assets/icons/Group.svg';
import Energy from '../assets/icons/Energy 1.svg';
import ChestPain from '../assets/icons/Chest Pain 2.svg';
import Aduri from '../assets/images/Aduri Begum.png';
import Mangai from '../assets/images/Mangai Pandian.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const patientsExperienceCardData = [
  {
    id: '1',
    patientName: 'Aduri Begum',
    diseaseType: 'Asthmatic',
    benefits: [
      {
        icon: BLSNS,
        title: 'No breathlessness',
      },
      {
        icon: Group,
        title: 'No chest Pain',
      },
      {
        icon: Energy,
        title: 'Better energy level',
      },
    ],
    title: 'Reduce Breathlessness',
    imageSrc: Aduri,
    description:
      'I am 43 years old and I have a asthma since last 10 years. Recently my coughing flared up and my health deteriorated, I was on bed rest for 3 months and my doctor advised me to download Breathefree APP. I joined the live sessions and diligently follow it. I am thankful to Doctor and her sessions have helped me. Thank you to Team Breathefree for improving my life.',
  },
  {
    id: '2',
    patientName: 'Mangai Pandian',
    diseaseType: 'Asthamatic',
    benefits: [
      {
        icon: BLSNS,
        title: 'No breathlessness',
      },
      {
        icon: ChestPain,
        title: 'No chest Pain',
      },
      {
        icon: Energy,
        title: 'Better energy level',
      },
    ],
    title: 'Reduce Breathlessness',
    imageSrc: Mangai,
    description:
      'I joined this program as I am a chronic asthmatic person. I am 74 years old. Doctor has been too good from my first session. She is very understanding and professional. After joining the session, I don’t have wheezing or cold which I had in the beginning. Even my frozen shoulder has become quite flexible with the warmup exercise. Thank you for your good service.',
  },
];

function PatientExperience() {
  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl">
      <div className="mx-auto max-w-screen-md text-left mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900">
          Experience with Breathefree's lung care program
        </h2>
      </div>

      <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={60}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay
        showArrows={false}
      >
        {patientsExperienceCardData.map(({ id, patientName, diseaseType, description, imageSrc, benefits }) => (
          <div
            className="mb-12 w-[85%] mx-auto bg-gradient-to-tr rounded-lg p-4 border-[1px] border-green-700"
            key={id}
          >
            <div className="flex gap-3 items-center">
              <div className="bg-gray-200 rounded-full w-20 h-20 overflow-hidden flex items-center justify-center">
                <img src={imageSrc} alt={'program-benefits'} className="h-full bg-cover" />
              </div>
              <div>
                <h3 className="text-left">{patientName}</h3>
                <p className="text-left text-sm text-green-700">{diseaseType}</p>
              </div>
            </div>

            <div className="bg-gray-200 p-2 rounded-lg mt-4">
              {benefits?.map(({ title, icon }) => (
                <div key={title} className="flex items-center gap-3 mt-2">
                  <div className="h-6 w-6">
                    <img src={icon} alt={'program-benefits'} className="h-full bg-cover" />
                  </div>
                  <p className="text-gray-700">{title}</p>
                </div>
              ))}
            </div>

            <h4 className="text-green-700 mt-2">Breathefree's Lung Care Program</h4>
            <div className="flex justify-start gap-1 flex-wrap pl-1">
              <p className="list-disc text-gray-700 relative">
                <span className="w-[5px] h-[5px] bg-gray-800 rounded-full absolute top-2 "></span>
                <span className="mx-2">Diet</span>
              </p>
              <p className="list-disc text-gray-700 relative">
                <span className="w-[5px] h-[5px] bg-gray-800 rounded-full absolute top-2 "></span>
                <span className="mx-2">Physiotherapy Sessions</span>
              </p>
            </div>

            <div className="bg-green-600 text-white rounded-lg text-left px-2 py-4 mt-4">{description}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default PatientExperience;
